import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import { NavLink } from 'react-router-dom'

function scrollUp(){
  window.scrollTo(0, 0)
}

const CareerItem = props => {
  let url = '/career/' + props.vacancyUrl
  return(
    <div className="overflow animated-box-wrapper">
      <ScrollAnimation animateIn="fadeInUp">
        <div className="career-item">
          <h3>{props.vacancyName}</h3>
          <p>{props.description}</p>
          <NavLink to={url} className="button button-primary" onClick={scrollUp}>Apply Now</NavLink>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default CareerItem