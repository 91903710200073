import React from 'react'
import {NavLink} from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll'
import Opening from './Opening'
import img3 from '../img/img-3.png'
import img5 from '../img/img-5.png'
import icon5 from '../img/icon-5.png'
import icon6 from '../img/icon-6.png'
import icon7 from '../img/icon-7.png'
import icon8 from '../img/icon-8.png'

function scrollUp(){
  window.scrollTo(0, 0)
}

const About = () => {
  return(
    <div className="about-page">
      <Opening
        blockId="about"
        label="About Us"
        description={[
          "Qualia is a direct advertiser that works with the Worldwide market with more than 200 partners from all over the world.", <br key={3} />, <br key={4} />,
          "Our account managers aim to provide our advertisers with high-quality leads, and our affiliates – with ultimate opportunities for traffic monetization.", <br key={5} />, <br key={6} />,
          "We believe your business deserves nothing less."
        ]}
        img={img3}
      />

      <section className="about-second-section" id="secondSection">
        <div className="container">
          <div className="about-second-section-content overflow">
            <ScrollAnimation animateIn="fadeInRight">
              <p>We are passionate about performance marketing and constantly improving to make your experience with us better. We empower publishers to manage demand path optimization via a suite of technology solutions.</p>
              <p>Among our clients, there are a lot of portals, online stores, projects – very popular websites in their subjects. We promote not the website, but the client’s business. Our goal is your successful business.</p>
            </ScrollAnimation>
          </div>
        </div>
      </section>

      <section className="about-counters">
        <div className="container">
          <div className="about-counters-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="content-box overflow">
                  <ScrollAnimation animateIn="fadeInUp">
                    <span>842.4M</span> Daily Impressions
                  </ScrollAnimation>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="content-box overflow">
                  <ScrollAnimation animateIn="fadeInUp" delay={100}>
                    <span>21.6K</span> Advertisers
                  </ScrollAnimation>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="content-box overflow">
                  <ScrollAnimation animateIn="fadeInUp" delay={200}>
                    <span>2.8M</span> Campaigns last month
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="about-bg-wrapper">
        <section className="about-2">
          <div className="container">
            <div className="about-2-content owerflow">
              <ScrollAnimation animateIn="fadeInLeft">
                <p>Our company shares digital advertising expertise, market knowledge, and an understanding of technology.</p>
                <p>We have young and vivid team members, among us, there are a lot of creative people, strong professionals, and versatile personalities.</p>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        <section className="about-3">
          <div className="container">
            <div className="about-3-content overflow">
              <div className="row">
                <div className="col-lg-3 col-md-6 overflow">
                  <ScrollAnimation animateIn="fadeInUp">
                    <div className="content-box">
                      <div className="img-wrapper icon">
                        <img src={icon5} alt="" />
                      </div>
                      <p>Personal approach to every partner</p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-3 col-md-6 overflow">
                  <ScrollAnimation animateIn="fadeInUp" delay={100}>
                    <div className="content-box">
                      <div className="img-wrapper icon">
                        <img src={icon6} alt="" />
                      </div>
                      <p>Strong media buying team</p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-3 col-md-6 overflow">
                  <ScrollAnimation animateIn="fadeInUp" delay={200}>
                    <div className="content-box">
                      <div className="img-wrapper icon">
                        <img src={icon7} alt="" />
                      </div>
                      <p>3 years of experience in the field</p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-3 col-md-6 overflow">
                  <ScrollAnimation animateIn="fadeInUp" delay={300}>
                    <div className="content-box">
                      <div className="img-wrapper icon">
                        <img src={icon8} alt="" />
                      </div>
                      <p>15 professionals on the team</p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-4">
          <div className="container">
            <div className="about-4-content">
              <div className="img-wrapper overflow">
                <ScrollAnimation animateIn="fadeInLeft">
                  <img src={img5} alt="" />
                </ScrollAnimation>
              </div>
              <div className="text-wrapper overflow">
                <ScrollAnimation animateIn="fadeInLeft" delay={100}>
                  <p>In connection with the opening of new projects, we are looking for new team members.</p>
                  <p>If you want to become a part of our team see the list of open positions.</p>
                  <p>Let’s create successful businesses together!</p>
                </ScrollAnimation>
              </div>
              <div className="button-wrapper overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={200}>
                  <NavLink to="/career" className="button button-primary" onClick={scrollUp}>Join Us</NavLink>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default About