import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import Utils from '../utils.js'

const fieldName = {
  name: 'First and Last Name',
  email: 'Email',
  companyName: 'Company Name',
  companyWebsite: 'Website Name',
  skypeOrTelegramName: 'Nick on ',
  message: 'Message'
}


function inputFocus(e){
  e.target.classList.add('focus')
  e.target.closest('.input-wrapper').querySelector('.form-label').classList.add('focus')
}

function inputBlur(e){
  const $this = e.target
  if($this.value === ''){
    $this.closest('.input-wrapper').querySelector('.form-label').classList.remove('focus')
    $this.classList.add('error')
  } else {
    $this.classList.remove('error')
  }
  $this.classList.remove('focus')
}

function submitForm(e){
  e.preventDefault()
  const data = {}
  const $this = e.target
  const inputs = [...$this.querySelectorAll('.form-input')]
  const submitButton = $this.querySelector('button[type=submit]')
  let errors = false
  fieldName.skypeOrTelegramName += document.querySelector('#messenger').value
  inputs.forEach(input => {
    if(input.value !== ''){
      if(input.getAttribute('name') !== 'messenger'){
        data[fieldName[input.getAttribute('name')]] = input.value
      }
    } else {
      errors = true
      input.classList.add('error')
    }
  })
  if(!errors){
    Utils.sendPost('/api.php', 'POST', JSON.stringify(data), {'Content-Type':'application/json'})
      .then(response => Utils.processResponse(response, document.querySelector('#homeForm')))
    submitButton.disabled = true
  }
}

const HomeForm = () => {
  return(
    <div className="home-page-form-wrapper">
      <form id="homeForm" onSubmit={submitForm}>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="name">Enter your first and last name:</label>
          <input 
            className="form-input"
            id="name"
            type="text"
            name="name"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="email">Enter your email:</label>
          <input 
            className="form-input"
            id="email"
            type="email"
            name="email"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="companyName">Your company name:</label>
          <input
            className="form-input"
            id="companyName"
            type="text"
            name="companyName"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="companyWebsite">Company website:</label>
          <input
            className="form-input"
            id="companyWebsite"
            type="text"
            name="companyWebsite"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="skypeOrTelegramName">Choose Skype or Telegram:</label>
          <input
            className="form-input"
            id="skypeOrTelegramName"
            type="text"
            name="skypeOrTelegramName"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
          <select id="messenger" className="form-input" name="messenger">
            <option value="skype">Skype</option>
            <option value="telegram">Telegram</option>
          </select>
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="message">Your Message:</label>
          <textarea
            className="form-input"
            id="message"
            name="message"
            onFocus={inputFocus}
            onBlur={inputBlur}
          ></textarea>
        </div>
        <div className="captcha-wrapper">
          <ReCAPTCHA sitekey="Your client site key" />
        </div>
        <div className="submit-wrapper">
          <button className="button button-primary" type="submit">Send</button>
        </div>
      </form>
    </div>
  )
}

export default HomeForm