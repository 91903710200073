import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Opening from './Opening'
import HomeForm from './HomeForm'
import publishersImg from '../img/img-2.png'
import icon1 from '../img/icon-1.png'
import icon2 from '../img/icon-2.png'
import icon3 from '../img/icon-3.png'
import icon4 from '../img/icon-4.png'
import img1 from '../img/img-1.png'

const Home = () => {
  return(
    <div className="home-page">
      <Opening
        blockId="home"
        label={["Qualia", <br key={1} />, "Your Exclusive", <br key={2} />, "Direct Partner"]}
        img={img1}
      />

      <section className="how-we-works" id="secondSection">
        <div className="container">
          <div className="how-we-works-content overflow">
            <ScrollAnimation animateIn="fadeInRight">
              <p>Qualia works with the Worldwide market with more than 200 partners from all over the world.</p>
              <p>We aim to provide our advertisers with high-quality leads, and our affiliates – with ultimate opportunities for traffic monetization.</p>
            </ScrollAnimation>
          </div>
        </div>
      </section>

      <section className="products white-text" id="products">
        <div className="container">
          <div className="products-content">
            <div className="text-wrapper overflow" id="products-anchor">
              <ScrollAnimation animateIn="fadeInLeft">
                <h2>Our Specialization</h2>
              </ScrollAnimation>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={300}>
                  <div className="content-box">
                    <h4>Movies</h4>
                    <ul className="disc">
                      <li>Hottest Movies and TV Shows</li>
                      <li>In-house High Converting Campaigns</li>
                      <li>API for White-label and Custom made LPs</li>
                      <li>Covering over 65 geos</li>
                    </ul>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={400}>
                  <div className="content-box">
                    <h4>Sports</h4>
                    <ul className="disc">
                      <li>All Sports and Leagues supported</li>
                      <li>Special LPs for top Sporting Events</li>
                      <li>In-house High Converting Campaigns</li>
                      <li>Covering over 65 geos</li>
                    </ul>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={500}>
                  <div className="content-box">
                    <h4>Sweepstakes</h4>
                    <ul className="disc">
                      <li>Most Popular Products in the Market</li>
                      <li>Custom Made Pages by Demand</li>
                      <li>In-house High Converting Campaigns</li>
                      <li>Covering over 65 geos</li>
                    </ul>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={600}>
                  <div className="content-box">
                    <h4>Downloads & eBooks</h4>
                    <ul className="disc">
                      <li>Top Selling Titles</li>
                      <li>Generic Download Pages</li>
                      <li>Downloads of Music, Games and Movies</li>
                      <li>Covering over 65 geos</li>
                    </ul>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={700}>
                  <div className="content-box">
                    <h4>Security</h4>
                    <ul className="disc">
                      <li>Self developed platform</li>
                      <li>100% Licensed content</li>
                      <li>Over 30 different home made pages</li>
                      <li>Covering over 65 geos</li>
                    </ul>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-4 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={800}>
                  <div className="content-box">
                    <h4>Workout</h4>
                    <ul className="disc">
                      <li>Hottest Yoga and Fitnes Clips</li>
                      <li>In-house High Converting Campaigns</li>
                      <li>API for White-label and Custom made LPs</li>
                      <li>Covering over 65 geos</li>
                    </ul>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="publishers" id="publishers">
        <div className="container">
          <div className="row" id="publishers-anchor">
            <div className="col-lg-6">
              <div className="img-wrapper overflow">
                <ScrollAnimation animateIn="zoomIn">
                  <img src={publishersImg} alt="" />
                </ScrollAnimation>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="publishers-content overflow">
                <ScrollAnimation animateIn="fadeInLeft" delay={300}>
                  <h2>Publishers</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" delay={400}>
                  <p>Maximize your revenue with Qualia.</p>
                  <p>You can stop searching for better terms, our offers are immediately accessible to you: just focus on driving results and let us take care of everything else.</p>
                  <p>We offer our affiliates:</p>
                  <ul className="disc">
                    <li>Top Payouts & High CR%</li>
                    <li>Flexible Payment Terms</li>
                    <li>Multi-language Landing Page</li>
                    <li>Dedicated Support</li>
                    <li>Transparent Analytics</li>
                  </ul>
                  <div className="button-wrapper">
                    <a href="#contact" className="button button-primary">Contact Us</a>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="publishers_2" id="publishers_2">
        <div className="container">
          <div className="publishers_2-content">
            <div className="text-wrapper overflow">
              <ScrollAnimation animateIn="fadeInLeft">
                <h6 className="text-uppercase">Just focus on driving results and let us take care of everything else</h6>
              </ScrollAnimation>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={300}>
                  <div className="content-box">
                    <div className="img-wrapper icon">
                      <img src={icon1} alt="" />
                    </div>
                    <h4>Worldwide GEO’s</h4>
                    <p>Offering over 150 GEO’s for your traffic monetization</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-3 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={400}>
                  <div className="content-box">
                    <div className="img-wrapper icon">
                      <img src={icon2} alt="" />
                    </div>
                    <h4>On-time Payments</h4>
                    <p>Weekly payments. Webmoney, Payoneer, Wire</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-3 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={500}>
                  <div className="content-box">
                    <div className="img-wrapper icon">
                      <img src={icon3} alt="" />
                    </div>
                    <h4>Highest Earnings</h4>
                    <p>Providing high payouts – because our affiliates deserve it</p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-lg-3 col-md-6 overflow">
                <ScrollAnimation animateIn="fadeInUp" delay={600}>
                  <div className="content-box">
                    <div className="img-wrapper icon">
                      <img src={icon4} alt="" />
                    </div>
                    <h4>Personal Support</h4>
                    <p>Got any questions? Contact your affiliate manager to get help</p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact" id="contact">
        <div className="container">
          <div className="contact-content overflow" id="contact-anchor">
            <div className="text-wrapper">
              <ScrollAnimation animateIn="fadeInLeft">
                <h2>Let’s Work Together</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" delay={100}>
                <p>Leave a request and we will contact you to discuss the details of cooperation</p>
              </ScrollAnimation>
            </div>
            <div className="form-wrapper">
              <ScrollAnimation animateIn="fadeInUp" delay={200}>
                <HomeForm />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home