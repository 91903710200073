import React from 'react'
import {NavLink} from 'react-router-dom';
import linkedin from '../img/icon-linkedin.png'
import facebook from '../img/icon-facebook.png'
import instagram from '../img/icon-instagram.png'

window.addEventListener('DOMContentLoaded', () => {
  const footer = document.querySelector('.footer')
  if(footer){
    currentYear()
    simpleHeaderOnFooter()
  }
})

function currentYear(){
  const initialDate = new Date()
  const yearSpan = document.querySelector('.current-year')
  yearSpan.textContent = initialDate.getFullYear()
}

function simpleHeaderOnFooter(){
  const header = document.querySelector('.header')
  const footer = document.querySelector('.footer')
  const legalBlock = footer.querySelector('.legal')
  const legalLinks = [...legalBlock.querySelectorAll('a')]
  legalLinks.forEach(link => {
    link.addEventListener('click', () => {
      header.classList.add('header-simple')
    })
  })
}

function scrollUp(){
  window.scrollTo(0, 0)
}

const Footer = () => {
  return(
    <footer className="footer">
      <div className="container">
        <div className="footer-content overflow">
          <ul className="social">
            <li>
              <a href="/">
                <div className="img-wrapper icon">
                  <img src={linkedin} alt="" />
                </div>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="img-wrapper icon">
                  <img src={facebook} alt="" />
                </div>
              </a>
            </li>
            <li>
              <a href="/">
                <div className="img-wrapper icon">
                  <img src={instagram} alt="" />
                </div>
              </a>
            </li>
          </ul>
          <ul className="legal">
            <li><NavLink to="/privacy-policy" onClick={scrollUp}>Privacy Policy</NavLink></li>
            <li><NavLink to="/agency-terms" onClick={scrollUp}>Advertiser / Agency Terms and Conditions</NavLink></li>
            <li><NavLink to="/publisher-terms" onClick={scrollUp}>Publisher Terms and Conditions</NavLink></li>
          </ul>
          <div className="copyright">
            &copy; <span className="current-year"></span> Qualia
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer