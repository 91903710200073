import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {BrowserRouter} from 'react-router-dom';
import State from './state/State'
import './css/bootstrap.css'
import './css/animate.min.css'
import './css/style.scss'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App state={State} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)