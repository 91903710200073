import React from 'react'

function inputFocus(e){
  e.target.classList.add('focus')
  e.target.closest('.input-wrapper').querySelector('.form-label').classList.add('focus')
}

function inputBlur(e){
  const $this = e.target
  if($this.value === ''){
    $this.closest('.input-wrapper').querySelector('.form-label').classList.remove('focus')
    $this.classList.add('error')
  } else {
    $this.classList.remove('error')
  }
  $this.classList.remove('focus')
}

function submitForm(e){
  const $this = e.target
  const inputs = [...$this.querySelectorAll('.form-input')]
  inputs.forEach(input => {
    if(input.value === ''){
      input.classList.add('error')
    }
    const emptyInput = inputs.some(input => input.value === '')
    if(emptyInput) e.preventDefault()
  })
}

const Login = () => {
  return(
    <div className="login-page">
      <div className="login-form-box">
        <h3>Login Form</h3>
        <form id="loginForm" onSubmit={submitForm}>
          <div className="input-wrapper">
            <label className="form-label" htmlFor="loginName">Username</label>
            <input 
              className="form-input" 
              id="loginName" 
              type="text" 
              name="loginName" 
              onFocus={inputFocus}
              onBlur={inputBlur}
            />
          </div>
          <div className="input-wrapper">
            <label className="form-label" htmlFor="loginPassword">Password</label>
            <input 
              className="form-input" 
              id="loginPassword" 
              type="password" 
              name="loginPassword" 
              onFocus={inputFocus}
              onBlur={inputBlur}
            />
          </div>
          <div className="submit-wrapper">
            <button className="button button-primary" type="submit">Login</button>
          </div>
        </form>
        <div className="signup-text">
          <p>Not a partner - <a href="/#contact">Sign Up Now</a></p>
        </div>
      </div>
    </div>
  )
}

export default Login