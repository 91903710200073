import React from 'react'
import {NavLink} from 'react-router-dom';
import logo from '../img/logo.png'

window.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.header')
  if(header){
    fixedHeader()
    simpleHeaderOnHeader()
    if(document.location.pathname === '/privacy-policy' || document.location.pathname === '/agency-terms' || document.location.pathname === '/publisher-terms'){
      header.classList.add('header-simple')
    }
  }
})

function fixedHeader(){
  const header = document.querySelector('.header')
  const headerButton = header.querySelector('.button')
  if(window.pageYOffset > 150){
    header.classList.add('fixed')
    headerButton.classList.remove('button-white')
    headerButton.classList.add('button-primary')
  }
  let scrolled
	window.addEventListener('scroll', () => {
    scrolled = window.pageYOffset || document.documentElement.scrollTop
    if(scrolled > 150){
      header.classList.add('fixed')
      headerButton.classList.remove('button-white')
      headerButton.classList.add('button-primary')
    } else {
      header.classList.remove('fixed')
      headerButton.classList.add('button-white')
      headerButton.classList.remove('button-primary')
    }
  })
}

function simpleHeaderOnHeader(){
  const header = document.querySelector('.header')
  const headerNavigation = header.querySelector('.header-navigation')
  const headerLinks = [...headerNavigation.querySelectorAll('a')]
  headerLinks.forEach(link => {
    link.addEventListener('click', () => {
      header.classList.remove('header-simple')
    })
  })
}

function scrollUp(){
  window.scrollTo(0, 0)
}

function showMobileNav(){
  const body = document.querySelector('body')
  const header = document.querySelector('.header')
  const navBlock = header.querySelector('.header-navigation')
  body.classList.add('overflow')
  navBlock.classList.add('show')
}

function hideMobileNav(){
  const body = document.querySelector('body')
  const header = document.querySelector('.header')
  const navBlock = header.querySelector('.header-navigation')
  body.classList.remove('overflow')
  navBlock.classList.remove('show')
}

const Header = () => {
  const anchor = (e) => {
    const scroll = e.target.dataset.scroll
    const scrollBlock = document.querySelector(scroll)
    if(scrollBlock){
      const offsetTop = scrollBlock.offsetTop - 160
      window.scroll({
        top: offsetTop,
        behavior: 'smooth'
      })
    } else {
      window.location.replace(`/${scroll.split('-')[0]}`)
    }
  }
  
  return(
    <header className="header">
      <div className="container">
        <div className="header-content">
          <div className="header-logo">
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
          </div>
          <div className="header-nav-wrapper">
            <nav className="header-navigation">
              <span className="hide-nav" onClick={hideMobileNav}></span>
              <ul>
                <li><NavLink to="/" exact onClick={() => {scrollUp(); hideMobileNav();}}>Home</NavLink></li>
                <li><NavLink to="/about" onClick={() => {scrollUp(); hideMobileNav();}}>About Us</NavLink></li>
                <li><button data-scroll="#products-anchor" onClick={e => {anchor(e); hideMobileNav()}}>Products</button></li>
                <li><button data-scroll="#publishers-anchor" onClick={e => {anchor(e); hideMobileNav()}}>Publishers</button></li>
                <li><NavLink to="/career" onClick={() => {scrollUp(); hideMobileNav();}}>Career</NavLink></li>
                <li><button data-scroll="#contact-anchor" onClick={e => {anchor(e); hideMobileNav()}}>Contact Us</button></li>
              </ul>
            </nav>
            <div className="header-login-button-wrapper">
              <NavLink to="/login" target="_blank" className="button button-white">Log In</NavLink>
            </div>
            <div className="hamburger-wrapper">
              <div className="hamburger" onClick={showMobileNav}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header