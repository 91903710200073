import {Switch, Route, Redirect, useHistory} from 'react-router-dom'
import Header from './partials/Header'
import Footer from './partials/Footer'
import Home from './partials/Home'
import About from './partials/About'
import Career from './partials/Career'
import Vacancy from './partials/Vacancy'
import PrivacyPolicy from './partials/PrivacyPolicy'
import AgencyTerms from './partials/AgencyTerms'
import PublisherTerms from './partials/PublisherTerms'
import Login from './partials/Login'

const App = props => {
  const history = useHistory()
  const showParts = () => !history.location.pathname.includes('login')
  return(
    <div className="App">
      {showParts() && <Header/>}
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route path="/about" render={() => <About />} />
        <Route exact path="/career" render={() => <Career vacancies={props.state.vacancies} />} />
        <Route exact path="/career/:vacUrl" render={() => <Vacancy vacancies={props.state.vacancies} /> }/>
        <Route path="/privacy-policy" render={() => <PrivacyPolicy />} />
        <Route path="/agency-terms" render={() => <AgencyTerms />} />
        <Route path="/publisher-terms" render={() => <PublisherTerms />} />
        <Route path="/login" render={() => <Login />} />
        <Redirect to="/" />
      </Switch>
      {showParts() && <Footer/>}
    </div>
  )
}

export default App