import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

function scrollToAnchor(){
  window.scroll({
    top: document.querySelector('#secondSection').offsetTop - 160,
    behavior: 'smooth'
  })
}

const Opening = props => {
  return(
    <section className="opening-section" id={props.blockId}>
      <div className="container">
        <div className="opening-section-content overflow">
          <ScrollAnimation animateIn="fadeInLeft">
            <h1>{props.label}</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" delay={300}>
            <p>{props.description}</p>
          </ScrollAnimation>
        </div>
        <div className="opening-section-img img-wrapper overflow">
          <ScrollAnimation animateIn="zoomIn" delay={600}>
            <img src={props.img} alt="" />
          </ScrollAnimation>
        </div>
      </div>
      <div className="scroll-down" onClick={scrollToAnchor}>
        <span className="scroll-down-line"></span>
        <span className="scroll-down-text">Scroll down</span>
      </div>
    </section>
  )
}

export default Opening