import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Opening from './Opening'
import CareerItem from './CareerItem'
import img4 from '../img/img-4.png'

const Career = props => {
  let careerList = props.vacancies.map((vacancy, index) => 
    <CareerItem
      vacancyName={vacancy.vacancyName}
      vacancyUrl={vacancy.vacancyUrl}
      description={vacancy.description}
      key={index}
    />
  )
  return(
    <div className="career-page">
      <Opening
        blockId="career"
        label="Career"
        description={[
          "We invite in our team active and talented specialists in their fields, those who like creating, and not only performing tasks.", <br key={1} />, <br key={2} />,
          "Our team needs real team players, everyone writes about this, but we really need them."
        ]}
        img={img4}
      />

      <section className="career-2" id="secondSection">
        <div className="container">
          <div className="career-2-content overflow">
            <ScrollAnimation animateIn="fadeInRight">
              <p>To help us grow at an even faster pace we are looking for motivated people with creative energy to join us</p>
            </ScrollAnimation>
          </div>
        </div>
      </section>

      <section className="career-3">
        <div className="container">
          <div className="career-3-content">
            <div className="text-wrapper overflow">
              <ScrollAnimation animateIn="fadeInLeft">
                <h2>Open Positions</h2>
              </ScrollAnimation>
            </div>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="career-list">
                  {careerList}
                </div>
              </div>
            </div>
            <div className="text-wrapper overflow animated-box-wrapper">
              <ScrollAnimation animateIn="fadeInLeft">
                <h4>Come to join the team — and let’s rock it!</h4>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Career