import React from 'react'
import {useParams, Redirect} from 'react-router-dom'
import ScrollAnimation from 'react-animate-on-scroll'
import Opening from './Opening'
import VacancyForm from './VacancyForm'

const Vacancy = props => {
  const {vacUrl} = useParams()
  const currentVacancy = props.vacancies.find(vacancy => {
    const fiendedVacancy = vacancy.vacancyUrl === vacUrl
    return fiendedVacancy ? fiendedVacancy : false
  })
  if(!currentVacancy) return(<Redirect to="/career" />)
  const requirements = (vacancy) => vacancy.requirements.map((requirement, index) => <li key={index}>{requirement}</li>)
  const responsibilities = (vacancy) => vacancy.responsibilities.map((responsibility, index) => <li key={index}>{responsibility}</li>)
  const offers = (vacancy) => vacancy.offers.map((offer, index) => <li key={index}>{offer}</li>)

  return(
    <div className={`vacancy-page ${currentVacancy.vacancyUrl}`}>
      <Opening
        label={currentVacancy.vacancyName}
        description={currentVacancy.description}
      />

      <section className="vacancy-2" id="secondSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="vacancy-2-content overflow">
                <ScrollAnimation animateIn="fadeInLeft">
                  <p className="text-uppercase"><strong>Requirements:</strong></p>
                  <ul className="disc">
                    {requirements(currentVacancy)}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft">
                  <p className="text-uppercase"><strong>Responsibilities:</strong></p>
                  <ul className="disc">
                    {responsibilities(currentVacancy)}
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft">
                  <p className="text-uppercase"><strong>We offer:</strong></p>
                  <ul className="disc">
                    {offers(currentVacancy)}
                  </ul>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vacancy-form">
        <div className="container">
          <div className="overflow animated-box-wrapper">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="vacancy-form-content">
                <h3>Apply for your dream job</h3>
                <VacancyForm 
                  vacancyName={currentVacancy.vacancyName}
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Vacancy