const showAlert = (el, text = '', position = 'beforeend', cssClass = 'success') => {
    text = text || 'Thank you! Your message has been sent'
    el.insertAdjacentHTML(position, `<div class="alert alert-${cssClass} text-center" role="alert">${text}</div>`)
}

const showPopup = message => {
    const $el = `<div class="popup-window"><div class="popup">${message}</div></div>`
    document.querySelector('body').insertAdjacentHTML('afterbegin', $el)
    setTimeout(() => {
        document.querySelector('body').removeChild(document.querySelector('.popup-window'))
    }, 3000)
}

const processResponse = (response, el) => {
    if (response.httpstatus === 200 && response.result.hasOwnProperty('email_id')) {
        el.style.display = 'none'
        showAlert(el.closest('div'))
    } else {
        showPopup(response.message)
    }
}
const sendPost = async (url, method = 'GET', body = null, headers = {}) => {
    try {
        const response = await fetch(url, {method, body, headers})
        if (!response.ok) {
        return {
            message: response.status === 413 ? 'Sorry, your file is too large.' : response.statusText,
            httpstatus: response.status
        }
        }
        return await response.json()
        } catch (e) {
            console.warn(e)
    }
}

export default {sendPost, processResponse}