import React from 'react'
import Utils from '../utils.js'

function inputFocus(e){
  e.target.classList.add('focus')
  e.target.closest('.input-wrapper').querySelector('.form-label').classList.add('focus')
}

function inputBlur(e){
  const $this = e.target
  if($this.value === ''){
    $this.closest('.input-wrapper').querySelector('.form-label').classList.remove('focus')
    $this.classList.add('error')
  } else {
    $this.classList.remove('error')
  }
  $this.classList.remove('focus')
}

function submitForm(e){
  e.preventDefault()
  const $this = e.target
  const inputs = [...$this.querySelectorAll('.form-input')]
  $this.querySelector('button[type=submit]').disabled = true
  inputs.forEach(input => {
    if(input.value === ''){
      if(input.type === 'file'){
        input.closest('.input-wrapper').classList.add('error')
      } else {
        input.classList.add('error')
      }
    }
  })
  const $vacancyForm = document.querySelector('#vacancyForm')
  if($vacancyForm.querySelectorAll('.error').length !== 0){
    $this.querySelector('button[type=submit]').disabled = false
    return
  }
  const formData = new FormData()
  formData.append('Full Name', $vacancyForm.querySelector('#vacancyName').value)
  formData.append('Email', $vacancyForm.querySelector('#vacancyEmail').value)
  formData.append('Phone', $vacancyForm.querySelector('#vacancyPhone').value)
  formData.append('Message', $vacancyForm.querySelector('#vacancyMessage').value)
  if($vacancyForm.querySelector('input[type="file"]').files[0]){
    formData.append('attached_file', $vacancyForm.querySelector('input[type="file"]').files[0])
  }
  formData.append('Subject', `Response to "${$vacancyForm.querySelector('input[name="vacancy"]').value}" position`)
  Utils.sendPost('/api.php', 'POST', formData).then(response => {
    $this.querySelector('button[type=submit]').disabled = false
    Utils.processResponse(response, $vacancyForm)
  })
}

function inputFileChange(e){
  const $this = e.target
  const inputWrapper = $this.closest('.input-wrapper')
  const textInput = inputWrapper.querySelector('.input-file-helper')
  textInput.value = $this.value
  if($this.value !== ''){
    $this.classList.remove('error')
    $this.closest('.input-wrapper').classList.remove('error')
  }
}

const VacancyForm = props => {
  return(
    <div className="vacancy-form-wrapper">
      <form id="vacancyForm" onSubmit={submitForm}>
        <input 
          type="hidden"
          name="vacancy"
          value={props.vacancyName}
        />
        <div className="input-wrapper">
          <label className="form-label" htmlFor="vacancyName">Full name:</label>
          <input 
            className="form-input"
            id="vacancyName"
            type="text"
            name="vacancyName"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="vacancyEmail">Email:</label>
          <input 
            className="form-input"
            id="vacancyEmail"
            type="email"
            name="vacancyEmail"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="vacancyPhone">Phone:</label>
          <input 
            className="form-input"
            id="vacancyPhone"
            type="text"
            name="vacancyPhone"
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
        </div>
        <div className="input-wrapper">
          <label className="form-label" htmlFor="vacancyMessage">Message:</label>
          <textarea
            className="form-input"
            id="vacancyMessage"
            name="vacancyMessage"
            onFocus={inputFocus}
            onBlur={inputBlur}
          ></textarea>
        </div>
        <div className="input-wrapper type-file">
          <div className="file-input-wrapper">
            <span>Browse</span>
            <input
              className="form-input"
              id="vacancyFile"
              type='file' 
              name="vacancyFile"
              onChange={inputFileChange}
            />
          </div>
          <input
            className="input-file-helper"
            type="text"
            placeholder="Upload Your CV" 
          />
        </div>
        <div className="submit-wrapper">
          <button className="button button-primary" type="submit" >Send</button>
        </div>
      </form>
    </div>
  )
}

export default VacancyForm