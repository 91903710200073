import React from 'react'

const PublisherTerms = () => {
  return(
    <div className="legal-page publisher-terms">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="legal-page-content">
              <h3>Publisher Terms and Conditions</h3>

              <p>1. Preamble<br />
              1.1 These Terms and Conditions (the “Agreement”) govern an individual’s participation as a member (the “Affiliate”) of the COMPANY Affiliate Network (the “Program”). The Affiliate covenants and agrees to be bound by this Agreement, Privacy Policy and Data Processing Addendum (DPA) located at https://www.COMPANY.com, as may be amended by COMPANY from time to time.</p>
              
              <p>1.2 TO ACCEPT THESE TERMS AND CONDITIONS AND CREATE AN AFFILIATE ACCOUNT, AN INDIVIDUAL MUST BE AN ADULT OF LEGAL AGE OF MAJORITY IN THE COUNTRY IN WHICH THE ACCOUNT IS REGISTERED. THE INDIVIDUAL WHO PROVIDES INFORMATION PURSUANT TO THE PROGRAM AND ACCEPTS THIS AGREEMENT HAS FULL LAWFUL POWER AND AUTHORITY TO ENTER INTO AND CARRY OUT THE TERMS OF THIS AGREEMENT, AND IS LEGALLY AND FINANCIALLY RESPONSIBLE FOR ALL ACTIONS ON THE ACCOUNT, INCLUDING THE ACTIONS OF CHILDREN AND ANYONE ELSE WITH ACCESS TO IT.</p>
              
              <p>1.3 For the purpose of this Agreement, "COMPANY" means COMPANY Ltd., Quijano Chambers, P.O. Box 3159, Road Town, Tortola, BVI., or another legal entity specified as "COMPANY" in the relevant Insertion Order signed between the parties hereof.</p>
              
              <p>2. Definitions<br />
              2.1 “Client” means a client of COMPANY.</p>
              
              <p>2.2 “Offer” means a promotional offer published by COMPANY on the Program Site, in an electronic mail or both, setting out an advertising offer on behalf of a Client and containing such additional terms and conditions as the Client and COMPANY, in their discretion, consider necessary from time to time.</p>
              
              <p>2.3 “Program Site” means the website for the Program operated by COMPANY and situated at https://www.COMPANY.com with all subdomains thereof and pages we operate.</p>
              
              <p>2.4 “Sub-Affiliate” means an independent third party contracted by the Affiliate.</p>
              
              <p>3. The Program<br />
              3.1 COMPANY will permit the Affiliate to participate in the Program, subject to compliance with the terms and conditions in this Agreement and in any policies established by COMPANY, from time to time, and incorporated by reference in this Agreement. As of March 2019, there exist 2 (two) types of Accounts under COMPANY Affiliate Program - 'Regular' and 'Smartlink Only'. Holders of the latter shall only have access to COMPANY Smartlink(s) and will not be able to browse through the full portfolio of offers. Smartlink Only Account holders may apply for an account type change and will have to undergo an approval procedure (provide additional information on promotion methods and/or identity, and complete an interview with a COMPANY representative).</p>
              
              <p>3.2 The Affiliate will be allowed to create only 1 (one) account by using its own identity info; any additional account must be expressly preapproved with a COMPANY representative in writing. If at any time COMPANY becomes aware of more than one account related to one and the same private individual or legal entity, created to deceive COMPANY or its partners, COMPANY may restrict, deny or terminate such accounts and/or any benefits derived from such accounts; COMPANY may also withhold payment of any commissions and/or other fees related to this breach that may be or become due or payable to the Affiliate.</p>
              
              <p>3.3 The Affiliate may engage Sub-Affiliates to distribute Offers in accordance with the Program, provided that:</p>
              
              <p>(i) the Affiliate has written approval from a COMPANY Representative to contract Sub-Affiliates;</p>
              
              <p>(ii) a verifiable name, address and telephone number for each Sub-Affiliate is delivered to COMPANY immediately upon request;</p>
              
              <p>(iii) the proposed Sub-Affiliate agrees, in form satisfactory to COMPANY, to abide by paragraphs 3.8, 4.3, 9.5, Sections 10, 11, 12, 13 and 14 of this Agreement and the COMPANY Policies (collectively, the “Sub-Affiliate Provisions”), as amended from time to time; and</p>
              
              <p>(iv) at no time will the Affiliate engage a Sub-Affiliate who, in the opinion of COMPANY is likely to bring the reputation or standing of COMPANY into disrepute or is otherwise unsuitable.</p>
              
              <p>3.4 Any breach by a Sub-Affiliate of the Sub-Affiliate Provisions will be deemed a breach of this Agreement by the Affiliate.</p>
              
              <p>3.5 The parties expressly agree that they are independent contractors, and that this Agreement does not in any way create a partnership, nor have the parties granted to each other any right or authority to assume or create any obligation of responsibility, express or implied, on behalf of or in the name of the other, or to bind the other in any manner whatsoever.</p>
              
              <p>3.6 It is further agreed that the Affiliate has no authority to create or assume in COMPANY's name or on its behalf any obligation, express or implied, or to act or purport to act as its agent or representative for any purpose whatsoever and the Affiliate shall not hold itself out as having any such authority.</p>
              
              <p>3.7 COMPANY may, in its sole discretion, confirm or otherwise verify or check, the truth and accuracy of any registration information provided by Affiliate. The Affiliate hereby covenants and guarantees to provide to COMPANY only the truthful and valid information regarding the Affiliate's identity and location; in case COMPANY or third-party payment provider requests the Affiliate to prove its identity and location, the Affiliate shall reply within 2 (two) business days from the day of request and provide valid proof of its identity and location. If at any time COMPANY, in its sole judgment and discretion, determines the Affiliate’s registration information to be misleading, inaccurate or untruthful, COMPANY may restrict, deny or terminate Affiliate’s account, Affiliate’s access and use of, and/or any benefits derived from Affiliate’s participation in the Program; COMPANY may also withhold payment of any commissions and/or other fees that may be or become due or payable to Affiliate.</p>
              
              <p>3.8 Affiliate will be solely responsible for the development, operation, and maintenance of the Affiliate website and for all materials that appear on the Affiliate website. Such responsibilities include, but are not limited to, the technical operation of the Affiliate website and all related equipment; creating and posting product reviews, descriptions, and references on the Affiliate website and linking those descriptions to the Program Site; the accuracy and propriety of materials posted on the Affiliate website; and ensuring that materials posted on the Affiliate website do not violate or infringe upon the rights of any third party and are not libelous or otherwise illegal. COMPANY disclaims all liability and responsibility for such matters.</p>
              
              <p>3.9 Other than as provided in this Section 3, Affiliate may not sublease, rent, lease, sell, resell, outsource or service any Offer, and any attempt to do so shall be null and void.</p>
              
              <p>4. Offer<br />
              4.1 Offers will be posted to the Program Site.</p>
              
              <p>4.2 COMPANY grants the Affiliate a limited, non-exclusive, non-transferable right to download Offers from the Program Site and to publish the same on the Affiliate’s websites and in electronic mail correspondence sent by the Affiliate, all of which must be done in accordance with this Agreement, COMPANY Policies, as amended from time to time, and any additional terms and conditions affixed to each of the said Offers.</p>
              
              <p>4.3 The Affiliate agrees not to modify, alter, misrepresent or embellish the Offer or any part of the Offer including without limitation any text or images provided by or on behalf of COMPANY or the Client in any way, directly or indirectly, without the express prior written consent of COMPANY.</p>
              
              <p>5. EMAILS FROM COMPANY<br />
              5.1 COMPANY shall send its Affiliates notifications of applicable offer changes via email and it's their own responsibility to monitor such emails and apply the changes on their end. In case an Affiliate provided a non-existent email or missed a notification, COMPANY shall not be liable for any losses resulting thereof. By default, any Affiliate account is subscribed to notifications from COMPANY but any Participant can opt out at any time under its Account Settings. In such case, COMPANY shall not be obliged to find other ways to communicate the said changes to the Affiliate; instead, it will be the Affiliate's own responsibility to get the information via the UI or COMPANY API or in any other way.</p>
              
              <p>5.2 An Affiliate may also choose to subscribe to Offer/Campaign cap alerts under Account settings. This option is not enabled by default.</p>
              
              <p>5.3 COMPANY also practices promo emails, notifying its Affiliates of new offers, special promotions and bonus programs, or providing promotion tips. This is a setting each Affiliate needs to choose on signup, but can be amended at any time under Account settings.</p>
              
              <p>6. Compensation<br />
              6.1 COMPANY will pay to the Affiliate a commission (the “Commission”), calculated in accordance with the payment terms outlined in each Offer posted by COMPANY on the Program Site. COMPANY applies either monthly NET 15 or weekly NET 7 payment plans at its sole discretion, unless mutually agreed otherwise. Notwithstanding of the selected payment method (e.g. paypal, payoneer, webmoney etc.) the Affiliate shall provide COMPANY with their actual bank details.</p>
              
              <p>6.2 Commissions will be paid to the Affiliate only following receipt by COMPANY of payment from the Client in respect of such Offer published in the Program online reporting system. COMPANY may, in its sole discretion and from time to time, elect to advance to the Affiliate part or all of the Commissions prior to receipt of payment from the Client, but in no event will COMPANY be obligated to do so. COMPANY may offer weekly payments to trusted Affiliates with proven traffic quality, whose earnings exceed a weekly threshold of $1'000.00 (One Thousand US dollars) for Regular Account holders and $500 (Five Hundred US dollars) for Smartlink Only Account holders but reserves the right to deny weekly payments to any Affiliate at its own discretion. An Affiliate can request its Account type switch only once, and only for the reason of accessing specific offers. If an Affiliate is caught requesting its Account type switch in order to manipulate COMPANY into faster payments, COMPANY reserves the right to deny such application and, if such requests persist, to terminate the Affiliate Account.</p>
              
              <p>6.3 The Affiliate acknowledges and agrees that payment of Commissions may be delayed where the Affiliate has not provided COMPANY with current particulars in accordance with paragraph 10.2 (xiii) of this Agreement and that in no case will COMPANY be liable to the Affiliate for any loss, costs or expenses directly or indirectly incurred by the Affiliate as the result of such delay.</p>
              
              <p>6.4 Minimum amount that can be paid to the Affiliate in a given billing period must exceed $500.00 (five hundred US dollars) for Regular Affiliate Account holders and $100.00 (one hundred US dollars) for Smartlink Only account holders, regardless of the selected payment currency. Payment in the amount less than the respective threshold, but not less than $100.00 (one hundred US dollars), is possible in case of termination of the Agreement mutually agreed by the parties or its cancelation that is not related to any breach of contractual provisions or applicable laws by the Affiliate, or upon individual agreement between the parties.</p>
              
              <p>6.5 Commissions due and payable by COMPANY to an Affiliate will not accrue interest.</p>
              
              <p>6.6 Payments to an Affiliate in accordance with this Section will be based upon the records kept by COMPANY and reported in COMPANY’s online reporting system and audited by the Clients, from time to time.</p>
              
              <p>6.7 In case the Affiliate's account is not active for a period of 12 (twelve) months and the Affiliate didn’t request the payment of its Commissions within these 12 (twelve) months, any and all Commission may be then deemed as void and written off the balance, as well as the account may be terminated.</p>
              
              <p>7. REFERRAL PROGRAM<br />
              7.1 For each third-party Affiliate that the Affiliate has referred to COMPANY Affiliate Program through a unique referral link received from COMPANY, the Affiliate will have the right to be paid 2% (2 per cent) of the referred Affiliate's earnings for 6 months from the date referral relations are established (unless other terms were agreed upon individually).</p>
              
              <p>7.2 For this purpose, the Parties agree that only the newly referred Affiliates will be eligible for COMPANY Referral Program and that no individuals or companies with pre-existing accounts shall be considered eligible for the effect.</p>
              
              <p>7.3 Referral commissions start to accrue when the Referred Affiliate(s) bills get paid, not on traffic origin (so as to include the most up-to-date amounts).</p>
              
              <p>7.4 Referral commissions can be withdrawn as soon as the minimum threshold of $100 is reached and will be added to the Affiliate's next bill.</p>
              
              <p>8. LOYALTY/ BONUS PROGRAMS<br />
              8.1 COMPANY Loyalty program and any other kind of encouragement/ bonus or gift program is offered to Affiliates at COMPANY's own discretion. COMPANY alone shall decide on any Affiliate's eligibility for the program and shall in no way be obligated to provide bonuses/ gifts that weren't expressly approved to the Affiliate. The Affiliate hereby expressly consents that any form of bonuses, gifts and/or otherwise promotion shall be the right of COMPANY but not the obligation.</p>
              
              <p>8.2 For further details on how COMPANY Loyalty Program works, any Affiliate can refer to the respective Terms and Conditions, situated under the "Loyalty" section of its COMPANY account.</p>
              
              <p>9. Fraud<br />
              9.1 If COMPANY determines, in its sole discretion, that the Affiliate or Sub-Affiliate has engaged in any activity that COMPANY considers to be fraudulent or which might bring the reputation or standing of COMPANY into disrepute either with the general public or with the Clients or potential Clients of COMPANY, or otherwise that the Affiliate or Sub-Affiliate has engaged in activities which might be considered fraudulent or in case the Affiliate violates any given Offer's promotion restrictions, COMPANY may but will not be obligated to: (i) suspend or terminate the Affiliate’s membership in the Program, without notice and (ii) release to a regulatory body or any governmental authority, information relating to the identity and location of the Affiliate if required to do so in order to comply with existing legislation.</p>
              
              <p>9.2 COMPANY may but will not be obliged to provide proof of fraudulent activity to the Affiliate, and proof of any share of traffic being fraudulent is reason enough to forfeit the whole commission that is due to the Affiliate. If an Affiliate feels COMPANY's judgement in determining illegitimacy of its traffic to be wrong, the Affiliate shall provide proof of the opposite by sharing access to its traffic source/tracker, providing creative material used for promotion of the given campaign or any other evidence that the Affiliate hasn't been engaged in fraudulent activities. If the Affiliate fails to provide satisfactory or any kind of such proof within 7 (seven) days of its payment being put on hold, COMPANY reserves the right to terminate its Affiliate Account and cancel payment of the applicable commissions, at its sole discretion and without any further obligations to the Affiliate.</p>
              
              <p>9.3 In the event of suspension or termination in accordance with paragraph 9.1 above, any Commission due and payable to the Affiliate in accordance with Section 6 at the time of suspension or termination will be deemed to be forfeited.</p>
              
              <p>9.4 For the purposes of this Agreement, fraudulent activity includes but is in no way limited to:</p>
              
              <p>(i) activity by the Affiliate or anyone for whom in law the Affiliate is responsible which is directly or indirectly intended to inflate the Commissions payable to the Affiliate;</p>
              
              <p>(ii) the generation of leads other than by a mechanism approved by COMPANY;</p>
              
              <p>(iii) activity by the Affiliate or anyone for whom in law the Affiliate is responsible which is not in accordance with the Program; and</p>
              
              <p>(iv) activity which is determined by the Client, in its discretion, to be fraudulent.</p>
              
              <p>9.5 COMPANY may at any time audit Affiliate for compliance purposes. Affiliate agrees to provide COMPANY with any reasonable information necessary to conduct an investigation into Affiliate’s compliance with law and this Agreement.</p>
              
              <p>10. Covenants, Representations and Warranties<br />
              10.1 The Affiliate represents and warrants that:</p>
              
              <p>(i) it has the authority and capacity to enter into and to be bound by this Agreement;</p>
              
              <p>(ii) to the best of its knowledge, there are no existing, pending or threatened claims or actions pending against the Affiliate;</p>
              
              <p>(iii) none of the Affiliate’s websites contain false or deceptive advertising or any machine-readable code including without limitation any virus, Trojan horse, work or other self-executing program;</p>
              
              <p>(iv) it owns or has the legal right to use and distribute all content, copyrighted material, products, and services displayed on or through its website or websites and in its electronic mail; and</p>
              
              <p>(v) it is not now a party to any agreement or business relationship which may conflict with this Agreement.</p>
              
              <p>10.2 The Affiliate covenants and agrees that:</p>
              
              <p>(i) it will, at all times, comply with all laws applicable in the jurisdiction where the Affiliate is situated and also where the Affiliate directly or indirectly conducts its business or markets an Offer;</p>
              
              <p>(ii) it will not enter into any agreement or business relationship or otherwise incur any obligation which might, in the opinion of COMPANY, conflict with this Agreement;</p>
              
              <p>(iii) it will, at all times, comply with the terms of this Agreement, and COMPANY Policies, as updated, amended and replaced by COMPANY, from time to time, in its sole discretion;</p>
              
              <p>(iv) it will not, without the express written consent of COMPANY, use or permit any person for who it is in law responsible to use any third-party trade-names or trade-marks;</p>
              
              <p>(v) it will not publish on any website or send in any electronic mail a universal resource locator or other link to any content or otherwise engage in or condone any practice, which, in the opinion of COMPANY is deceitful, defamatory, libelous, abusive, violent, prejudicial, obscene, likely to bring the reputation or standing of COMPANY into disrepute, or which otherwise would be illegal;</p>
              
              <p>(vi) it will at all times comply with the terms and conditions of any agreement or policy established by an Offer in which the Affiliate elects to participate;</p>
              
              <p>(vii) it will at all times comply with the provisions of the CAN-SPAM Act of 2003, as amended or replaced, from time to time;</p>
              
              <p>(viii) it will not post any content relating to the Offer to any Usenet newsgroup, chat room, bulletin board or ‘blog’ (save and excepting a chat room, bulletin board or blog which is operated or primarily owned by the Affiliate) without the express written consent of COMPANY;</p>
              
              <p>(ix) it will be responsible for the development, operation and maintenance of its website or websites and electronic mail, including without limitation the technical operation thereof, the creation, publication and accuracy of any content published on any such website or websites or in any electronic mail;</p>
              
              <p>(x) it will not attempt in any way to alter, modify, eliminate, conceal or otherwise render inoperable or ineffective any tags, source codes, links, pixels, modules or other data provided by or obtained from COMPANY;</p>
              
              <p>(xi) it will not “frame” or “mirror” any part of any pages hosted by the Client unless expressly permitted by COMPANY and the Client;</p>
              
              <p>(xii) it will not alter any website or electronic mail content provided by COMPANY; and</p>
              
              <p>(xiii) it will, at all times and from time to time provide COMPANY with written confirmation of a valid address, telephone number, electronic mail address and such other identifying or financial information as COMPANY may reasonably require;</p>
              
              <p>(xiv) it will not, at all times, overload or overburden COMPANY’s tracking system in any manner whatsoever, without limitation, making unreasonably frequent or multiple or heavyweight queries and by no means shall the amount of daily API calls exceed 500 (five hundred) unless expressly agreed with a COMPANY representative.</p>
              
              <p>10.3 The Affiliate can engage in email promotion of COMPANY Offers only where it is allowed by the Advertiser and says so in Offer description, or in other cases, after receiving express written permission from a COMPANY representative.</p>
              
              <p>If applicable, the Affiliate will comply with the following requirements with respect to any email containing or promoting an Offer that the Affiliate initiates or sends:</p>
              
              <p>(i) the "FROM" line shall clearly and accurately identify the sender or company that is "sending" or "initiating" the e-mail (as those terms are defined by the CAN-SPAM Act). Notwithstanding the foregoing requirement, the Affiliate shall not place the name of COMPANY, the subject of the advertisement or any false or misleading information in the FROM line;</p>
              
              <p>(ii) the "SUBJECT" line must be reasonably related to the subject matter of the message;</p>
              
              <p>(iii) the email must contain a clearly displayed, labeled, and functioning (for at least thirty days after the message is sent) unsubscribe link for both the Affiliate and Client. Each unsubscribe link must provide for a universal unsubscribe from receipt of commercial email from the Affiliate and/or Client, such that any request for removal via the link results in removal from all of the Affiliate’s and/or Client's lists, as applicable. Each link must require the message recipient to do no more than visit a single web page (and not submit any information other than email address) in order to unsubscribe;</p>
              
              <p>(iv) the email must include a statement that identifies the email as a commercial solicitation. This may be in the header, body or footer of the email; and</p>
              
              <p>(v) the email must contain a valid postal address for the sender. This postal address should be listed at the top or bottom of each email.</p>
              
              <p>10.4 The Affiliate will comply with the following additional requirements:</p>
              
              <p>(i) The Affiliate may only deliver emails to individuals who have provided express consents to receive email offers;</p>
              
              <p>(ii) in the event of a spam complaint, the Affiliate will provide the following information to the filer of the complaint within 2 (two) business days of receipt of the complaint:</p>
              
              <p>(a) the website at which the filer of the complaint signed up;</p>
              
              <p>(b) a link to the privacy policy of the sign-up site evidencing that the filer of the complaint has given permission to the Affiliate and/or Client to receive email offers;</p>
              
              <p>(c) the date the filer of the complaint signed up (and the time if available); and</p>
              
              <p>(d) the IP address from which the filer of the complaint signed up</p>
              
              <p>11. Indemnification<br />
              11.1 The Affiliate covenants and agrees to indemnify and save harmless COMPANY, its parent company and their respective shareholders, directors, officers, and employees (collectively, the “Indemnified Group”) from and against any and all claims or judgments, including all associated legal fees, expenses and disbursements actually incurred, arising out of any breach of this Agreement by the Affiliate or any Sub-Affiliate or the exercise by the Affiliate of any right under this Agreement or any act or omission of the Affiliate, a Sub-Affiliate or anyone for whom the Affiliate is in law responsible, including without limitation any damages, losses, consequential or otherwise, arising in any manner (including those arising from or incidental to any liability or other lawsuit, claim, demand or other action brought) as a consequence of any act or omission of the Affiliate or any person for whom the Affiliate is in law responsible, whether or not the Indemnified Group or any of them are named as a party defendant in any such proceedings and whether or not the Indemnified Group or any of them are alleged to be negligent or otherwise responsible for any damage or injury to persons or property. The obligation of the Affiliate to defend and indemnify as set out in this paragraph will survive termination of this Agreement for any reason and will not be otherwise limited by any other term or condition of this or any Agreement. COMPANY may, at its election in its sole discretion, assume the exclusive defense and control of any matter otherwise subject to indemnification by Affiliate. COMPANY may participate in the defense of all claims as to which it does not assume defense and control, and Affiliate shall not settle any such claim without COMPANY's prior written consent.</p>
              
              <p>12. Disclosure of Information<br />
              12.1 COMPANY or its directors, may, from time to time, disclose to the Affiliate certain information relating to COMPANY’s business or to COMPANY’s Clients, subsidiaries, Affiliates, agents, or employees; business and marketing plans, strategies and methods which may not be standard industry practice or which are not generally known in the industry; or studies, charts, plans, tales or compilations of business and industrial information acquired or prepared by or on behalf of COMPANY (all collectively referred to as the “Confidential Information”). The Affiliate acknowledges that Confidential Information will be provided at the sole discretion of COMPANY, and nothing in this Agreement obligates COMPANY, its directors, agents or employees to disclose or grant to the Affiliate access to any Confidential Information.</p>
              
              <p>12.2 Unless expressly authorized in writing by COMPANY, the Affiliate covenants and agrees:</p>
              
              <p>(i) to use the Confidential Information only for the purposes expressly contemplated in this Agreement; and</p>
              
              <p>(ii) that no Confidential Information will be disclosed to any third party, affiliate, subsidiary, Sub-Affiliate, agent, or employee of the Affiliate without the prior written consent of COMPANY, which may be unreasonably and arbitrarily withheld.</p>
              
              <p>12.3 The Affiliate acknowledges that COMPANY remains the sole and exclusive owner of all right, title and interest in and to the Confidential Information. The Affiliate agrees that the Confidential Information will not be copied or otherwise reproduced without the express prior written consent of COMPANY.</p>
              
              <p>12.4 Upon termination of this Agreement, or otherwise on demand by COMPANY, the Affiliate agrees that it will promptly deliver to COMPANY all notes, data, tapes, reference items, sketches, drawings, memoranda, records, diskettes, electronic communications in any form and any other materials in any way relating to any of the Confidential Information in the possession of the Affiliate or any affiliate, subsidiary, Sub-Affiliate, agent, or employee of the Affiliate.</p>
              
              <p>12.5 The Affiliate acknowledges and agrees that:</p>
              
              <p>(i) the provisions of this Section and the Affiliate’s agreement with the same are of the essence and constitute a material inducement to COMPANY to enter into this Agreement;</p>
              
              <p>(ii) the provisions of this Section shall be construed independently of any other provision of this Agreement, and the existence of any claim or cause of action the Affiliate may have against COMPANY, whether predicated on this Agreement or otherwise, shall not constitute a defense to the enforcement by COMPANY of the provisions of this Section;</p>
              
              <p>(iii) that any breach of this Section would cause irreparable harm to COMPANY for which damages might not be an adequate remedy, and the Affiliate therefore agrees that in the event of any such breach COMPANY will be entitled to seek, in addition to any other right accruing to COMPANY under this Agreement or otherwise in law or equity, injunctive relief against the Affiliate without the necessity of proving actual damages; and</p>
              
              <p>(iv) notwithstanding any other provision of this Agreement, this Section shall survive the termination of this Agreement, however caused.</p>
              
              <p>12.6 The Affiliate agrees to indemnify and save harmless COMPANY against any and all loss, costs or expenses, inclusive of court costs and legal fees on a solicitor and own client basis, which COMPANY may incur as the direct or indirect result of any unauthorized disclosure of the Confidential Information by the Affiliate or any person for whom the Affiliate is responsible, in law.</p>
              
              <p>13. Non-Competition<br />
              13.1 During the term of this Agreement and for a period of 6 (six) months thereafter, the Affiliate will not seek, in any way, to undermine the business standing and goodwill of COMPANY, and in particular, the Affiliate will not, directly or indirectly: (i) solicit or entice or attempt to solicit or entice any of the employees of COMPANY to enter into employment service with the Affiliate or a competitor of COMPANY; or (ii) directly or indirectly enter into any agreement or contract, written or otherwise, with any Client/contractor of COMPANY or otherwise provide services to any third party for the ultimate benefit of a Client which might, in the opinion of COMPANY, compete with any services provided by COMPANY to that Client; or (iii) solicit, engage, contract, license, or work with any client whose offer it or any of its Sub-Affiliates marketed hereunder without the prior written approval by an authorized party at COMPANY.</p>
              
              <p>13.2 Affiliate agrees and understands that COMPANY has incurred significant expense forming COMPANY's affiliate network and in providing services for the benefit of its Clients, and Affiliate agrees and understands that in the event of a breach by Affiliate of this section, Affiliate shall pay COMPANY as liquidated damages an amount equal to the amount paid by COMPANY to Affiliate within 6 (six) billable months preceding such breach. The liquidated damage remedies provided herein in this section shall not preclude COMPANY from seeking injunctive relief. Additionally, in the event of breach of this section by Affiliate in the form of solicitation or enticing of employees of COMPANY or any other person who provides services to COMPANY, the Affiliate shall pay additional liquidated damages in the amount equal to the total of all payments made by COMPANY to such employee/any other person who provides services to COMPANY during last 3 calendar years directly preceding such breach.</p>
              
              <p>14. Disclaimer and Limitation of Liability<br />
              14.1 COMPANY disclaims all warranties, conditions, representations, indemnities and guarantees with respect to any matter, whether express or implied (including without limitation any warranty of profitability, satisfactory quality, merchantability, fitness for any particular purpose, title and non-infringement).</p>
              
              <p>14.2 Affiliate acknowledges and agrees that any determination by COMPANY of whether or not given advertising materials and/or creatives are accurate, appropriate and do not violate any applicable law is based solely on the designation made to COMPANY by the relevant Client and that COMPANY is not responsible for any given Advertising Materials being misdesignated by the relevant Client.</p>
              
              <p>14.3 Notwithstanding any other provision of this Agreement, COMPANY additionally disclaims all obligations and liabilities on the part of COMPANY and those for whom it is in law responsible for any damages, including, but not limited to, indirect, special, and consequential damages, attorneys’ and experts’ fees, and court costs (even if COMPANY has been advised of the possibility of such damages, fees or costs), arising out of or in connection with this Agreement.</p>
              
              <p>14.4 In no circumstance will COMPANY be liable to the Affiliate for any consequential, indirect, special, punitive or incidental damages or lost profits of the Affiliate or the Affiliate’s successors or assigns (including without limitation claims for loss of goodwill, use of or reliance on the services provided hereunder, stoppage of other work or impairment of other assets) arising out of breach or failure of express or implied warranty, breach of contract, misrepresentation, negligence, strict liability in tort or otherwise. Without limiting the generality of the foregoing, any liability of COMPANY shall be limited to the total amount paid to Affiliate by COMPANY under this Agreement during the last 6 (six) billable months preceding the liability. Without limiting the foregoing, COMPANY will not be liable for any failure or delay resulting from any governmental action, fire, flood, insurrection, earthquake, power failure, riot, explosion, embargo, strikes whether legal or illegal, labor or material shortage, transportation interruption of any kind, work slowdown, actions or inaction of Affiliate or third parties, Affiliate’s equipment or software and/or any third party equipment or any other condition affecting production or delivery in any manner beyond the control of COMPANY.</p>
              
              <p>15. Termination<br />
              15.1 Either party may terminate this Agreement, at any time, on 48 (forty-eight) hours’ Notice to the other party. If COMPANY believes the Affiliate has breached this Agreement, it reserves the right to suspend the account of the Affiliate immediately. The account can later be restored if the situation is resolved amicably.</p>
              
              <p>15.2 Notwithstanding termination of this Agreement for any reason, the Affiliate covenants and agrees that it will continue to be bound by the terms and conditions set out in Sections 6, 10, 11, 12, 13, 14 and 16.1 of this Agreement.</p>
              
              <p>15.3 The agreement shall be entered for an indefinite term, unless terminated by either party.</p>
              
              <p>16. General<br />
              16.1 COMPANY may assign this Agreement without the Affiliate’s prior consent. The Affiliate may not assign this Agreement without the express written consent of COMPANY.</p>
              
              <p>16.2 COMPANY reserves the right to change, amend or alter this Agreement at any time, for any reason, or for no reason at all, at its sole discretion. The most recent version of this Agreement will be posted on COMPANY website and in the respective section of the Program tracking platform. Although COMPANY may provide notice of material changes to this Agreement to the Affiliate but it is the Affiliate’s sole responsibility to keep themselves informed of any such changes or amendments.</p>
              
              <p>16.3 Any notice or other communication (“Notice”) permitted or required by this Agreement will be in writing and given by personal delivery or transmitted by facsimile or electronic mail to the receiving party at the address provided to COMPANY by Affiliate in its COMPANY Program account. Any such Notice will be deemed to have been received on the date on which it was transmitted by facsimile or electronic mail or delivered.</p>
              
              <p>16.4 No term or condition of this Agreement will be deemed waived and no breach excused, unless such waiver or consent excusing the breach is in writing and signed by both parties.</p>
              
              <p>16.5 In the event that any term, covenant or condition of this Agreement is declared indefinite, invalid, illegal or unenforceable by a court having jurisdiction then this Agreement with respect to the remaining terms, covenants or conditions will continue in force.</p>
              
              <p>16.6 This Agreement will inure to the benefit of and be binding upon the respective successors, heirs and assigns of both COMPANY and the Affiliate.</p>
              
              <p>16.7 This Agreement constitutes the entire agreement between the parties with respect to the subject matter of this agreement, supersedes any previous or contemporaneous representations, negotiations, understandings, and agreements, oral or written.</p>
              
              <p>16.8 This Agreement shall be governed by, construed and enforced in accordance with the laws of England and Wales; all matters concerning the performance thereof shall be construed, interpreted, applied and governed in all respects in accordance with the laws England and Wales. Any dispute arising out of or in connection with this contract, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration under the LCIA Rules, which Rules are deemed to be incorporated by reference into this clause. The number of arbitrators shall be three. The exclusive seat or legal place of arbitration shall be London, UK. The language to be used in the arbitral proceedings shall be English.</p>
              
              <p>16.9 COMPANY DOES NOT GUARANTEE THAT THE AFFILIATE OR ANY THIRD PARTY WILL BE ABLE TO ACCESS COMPANY WEBSITE AT ANY PARTICULAR TIME. COMPANY SERVICES ARE PROVIDED ON AN “AS-IS, AS-AVAILABLE” BASIS. EXTERNAL FACTORS AND/OR FACTORS BEYOND COMPANY CONTROL MIGHT CAUSE SERVICE DISRUPTIONS AT TIMES FOR WHICH COMPANY MIGHT NOT HAVE ANY POWER OVER.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublisherTerms