let State = {
  vacancies: [
    {
      vacancyName: "Affiliate manager",
      vacancyUrl: "affiliate-manager",
      description: "As an affiliate manager for European market, you are responsible for management and expansion of the existing database partners. You will evaluate new affiliates in terms of their potential, such as traffic, revenue potential, and brand exposure. You will find and implement new strategies to attract and onboard partners with high earning potential.",
      requirements: [
        "Upper Intermediate level of English is a must",
        "Experience in the position of Affiliate Manager for more than a year",
        "Understanding of the main metrics of online marketing (CPA, CPL, CPM, CPC)",
        "Ability to work with affiliate systems",
        "Experience in searching and working with affiliates in different markets",
        "High level of communication skills",
        "Good analytical abilities and skills to work with large volumes of data",
        "The accumulated base and contacts of affiliates in different GEOs",
      ],
      responsibilities: [
        "Attracting and connecting new affiliates",
        "Maintaining effective communication with partners through correspondence",
        "Building long-term relationships with partners",
        "Regular analysis of attracted traffic",
        "Monitoring trends in affiliate marketing"
      ],
      offers: [
        "Competitive salary",
        "Long-term employment with 20 working-days paid annual leave",
        "Support from colleagues and automatization of time-consuming processes",
        "12 working days of paid sick leaves per year",
        "Family atmosphere and if you’re also a dog lover, it’s 100% match"
      ]
    },
    {
      vacancyName: "Front-End developer",
      vacancyUrl: "front-end-developer",
      description: "You will be a member of a cross functional team. Our project is stable, long-term and constantly adopts new technologies. Key project stakeholders are open for innovative ideas. This is a great opportunity to work in an successful team, apply and learn modern IT technologies.",
      requirements: [
        "At least 1 year+ experience as Front-End developer",
        "Real working experience with JavaScript and PHP",
        "HTML (5), CSS knowledge and adaptive layout experience",
        "Knowing of jQuery and Ajax fundamental issues",
        "Experience in maintaining servers based on nginx web-server",
        "Experience in working with MySQL",
        "Experience in working with GIT Version Control System",
        "Understanding of the key OOP principles",
        "Understanding of Linux operation (terminal, ssh, cron etc.)",
        "Ability to perform technical analysis"
      ],
      responsibilities: [
        "Optimization of existing company's projects",
        "Bug fixing and modification of websites",
        "Modification of templates and plugins",
        "Development of new products from scratch",
        "Ability to meet the deadlines and established requirements when bug fixing"
      ],
      offers: [
        "Competitive salary",
        "Long-term employment with 20 working-days paid annual leave",
        "Support from colleagues and automatization of time-consuming processes",
        "12 working days of paid sick leaves per year",
        "Family atmosphere and if you’re also a dog lover, it’s 100% match"
      ]
    },
    {
      vacancyName: "Project manager",
      vacancyUrl: "project-manager",
      description: "We invite in our Team an ambitious Project Manager, who will not only lead projects but also build and improve the process within the PM office.",
      requirements: [
        "Experience in the role of an IT Project manager for at least 1 year",
        "Confident knowledge of Agile (Scrum, Kanban)",
        "Experience in preparing specifications for the development team",
        "The ability to defend one's arguments",
        "A high level of communication skills",
        "The desire to effectively establish teamwork and teamwork with customers",
        "Strong interpersonal skills with the ability to build and maintain effective working relationships across multiple teams and at varying levels throughout the organization",
        "Level of English language knowledge - Intermediate and higher."
      ],
      responsibilities: [
        "Collection of project requirements",
        "Communication with the holding companies, company departments, partners of the company",
        "Setting tasks and monitoring their timely and high-quality implementation",
        "Management of the project team",
        "Participation in task testing to ensure that the developed functionality meets the initial requirements"
      ],
      offers: [
        "Competitive salary",
        "Long-term employment with 20 working-days paid annual leave",
        "Support from colleagues and automatization of time-consuming processes",
        "12 working days of paid sick leaves per year",
        "Family atmosphere and if you’re also a dog lover, it’s 100% match"
      ]
    }
  ]
}

export default State